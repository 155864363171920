exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-cancel-plan-index-tsx": () => import("./../../../src/pages/cancel-plan/index.tsx" /* webpackChunkName: "component---src-pages-cancel-plan-index-tsx" */),
  "component---src-pages-canceled-plan-index-tsx": () => import("./../../../src/pages/canceled-plan/index.tsx" /* webpackChunkName: "component---src-pages-canceled-plan-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-payment-method-index-tsx": () => import("./../../../src/pages/payment-method/index.tsx" /* webpackChunkName: "component---src-pages-payment-method-index-tsx" */),
  "component---src-pages-plans-index-tsx": () => import("./../../../src/pages/plans/index.tsx" /* webpackChunkName: "component---src-pages-plans-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../../src/pages/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-pages-theme-colors-index-tsx": () => import("./../../../src/pages/theme/Colors/index.tsx" /* webpackChunkName: "component---src-pages-theme-colors-index-tsx" */),
  "component---src-pages-theme-fonts-index-tsx": () => import("./../../../src/pages/theme/Fonts/index.tsx" /* webpackChunkName: "component---src-pages-theme-fonts-index-tsx" */),
  "component---src-pages-theme-index-tsx": () => import("./../../../src/pages/theme/index.tsx" /* webpackChunkName: "component---src-pages-theme-index-tsx" */)
}

